import axios from "axios";
import {
    ADMIN_APP_SESSION_KEY,
    defaultCookieOptions,
} from "common/constants";
import qs from "qs";
import { Cookies } from "react-cookie";

export const setupDAL = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

    axios.defaults.paramsSerializer = (params) => {
        return qs.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
        });
    };

    axios.interceptors.request.use(
        (req) => {
            const cookies = new Cookies();
            const userSession = cookies.get(ADMIN_APP_SESSION_KEY);
            req.headers = {
                Authorization: userSession
                    ? `Bearer ${userSession.accessToken}`
                    : `clientKey ${process.env.REACT_APP_API_CLIENT_KEY}| clientSecret ${process.env.REACT_APP_API_CLIENT_SECRET}`,
            };

            return req;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401) {
                const cookies = new Cookies();
                cookies.remove(ADMIN_APP_SESSION_KEY, defaultCookieOptions);
                
                // Store the timestamp of this authentication failure
                // The Login component will check this to prevent redirect loops
                localStorage.setItem('lastAuthFailure', Date.now().toString());

                window.location.href = window.location.origin + "/login";
            }
            return Promise.reject(error);
        },
    );
};
