import { message } from "antd";
import axios from "axios";
import {
    ADMIN_APP_SESSION_KEY,
    SESSION_KEY,
    defaultCookieOptions,
} from "common/constants";
import noop from "lodash/noop";
import { Cookies } from "react-cookie";

const redirectUri = encodeURIComponent(`${window.location.origin}/login`);

export const createRequest = async () => {
    return axios
        .post(`admin/auth/request`, { redirectUri })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
};

export const authenticate = async (authData) => {
    return axios
        .post("admin/auth", {
            ...authData,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error);
        });
};

export const impersonateUser = async (accessToUserId) => {
    return axios
        .get(`admin/user/impersonate/${accessToUserId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to get user impersonation data");
            console.error(error);
        });
};

//TODO:
// Add "sameSite" option
export const signIn = (authData, onError = noop) => {
    const cookies = new Cookies();

    authenticate(authData).then(async (data) => {
        if (data?.success) {
            const session = getSessionDataFromAccessResponse(data.result);
            cookies.set(ADMIN_APP_SESSION_KEY, session, {
                ...defaultCookieOptions,
            });
        } else {
            message.warning("You do not have access.");
            onError();
        }
    });
};

export const signOut = (callback = noop) => {
    const cookies = new Cookies();
    cookies.remove(ADMIN_APP_SESSION_KEY, defaultCookieOptions);
    cookies.remove(SESSION_KEY, defaultCookieOptions);
    
    callback();
};

export const getAuthVerifierFromCallback = (searchParams) => {
    let authData = {};

    const error = searchParams.get("error");

    if (error) {
        return authData;
    } else {
        const code = searchParams.get("code");

        authData = {
            redirectUri,
            authVerifier: code,
        };
    }

    return authData;
};

export const getSessionDataFromAccessResponse = (accessData) => {
    const {
        accessToUserId,
        accessToken,
        expireDate,
        email,
        sessionTypeId,
        sessionUserId,
        userId,
    } = accessData || {};

    const env = process.env.REACT_APP_ENV;

    return {
        userId: userId || accessToUserId,
        sessionUserId,
        sessionTypeId,
        accessToken,
        email,
        expireDate,
        env,
        timestamp: Date.now(), // Add a timestamp to know when this cookie was created
    };
};
