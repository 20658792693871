import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { message, Typography, Divider, Button } from "antd";

import { ReactComponent as Logo } from "images/logo.svg";
import { ReactComponent as GoogleLogo } from "images/GoogleLogo.svg";
import { ReactComponent as Vadar } from "images/vader.svg";

import "./Login.scss";
import {
    getAuthVerifierFromCallback,
    createRequest,
    signIn,
} from "./login.api";
import { useCookies } from "react-cookie";
import { ADMIN_APP_SESSION_KEY, defaultCookieOptions } from "common/constants";

const { Text } = Typography;

const CURR_YEAR = new Date().getFullYear();

//TODO:
// *Fix loading state
// *Fix error state
// *Add error/validation notifications
// *General styling and layout improvements

export const Login = () => {
    const navigate = useNavigate();
    const [cookie, , removeCookie] = useCookies([ADMIN_APP_SESSION_KEY]);
    const sessionCookie = cookie[ADMIN_APP_SESSION_KEY];

    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();

    // Create a localStorage key to track when session was last cleared due to 401
    // This addresses the multi-tab issue where one tab may clear the cookie but
    // another tab's memory still has the cookie
    useEffect(() => {
        // When the component mounts, check if we were redirected here by a 401 interceptor
        const lastAuthFailure = parseInt(
            localStorage.getItem("lastAuthFailure") || "0",
        );

        // If we have a sessionCookie, it might be stale if it was created before the last auth failure
        if (sessionCookie) {
            // Only auto-redirect if:
            // 1. There's no recent auth failure, OR
            // 2. The cookie was set AFTER the last auth failure (meaning it's a new valid session)
            if (
                !lastAuthFailure ||
                (sessionCookie.timestamp &&
                    sessionCookie.timestamp > lastAuthFailure)
            ) {
                const timeoutId = setTimeout(() => {
                    setLoading(false);
                    navigate("/brands");
                }, 100);

                return () => clearTimeout(timeoutId);
            }
        }
    }, [sessionCookie, navigate]);

    useEffect(() => {
        const authData = getAuthVerifierFromCallback(searchParams);

        if (authData?.authVerifier) {
            setLoading(true);
            signIn(authData, () => setLoading(false));
        }
    }, [searchParams]);

    const authenticationRequest = () => {
        removeCookie(ADMIN_APP_SESSION_KEY, defaultCookieOptions);

        setLoading(true);
        createRequest()
            .then((response) => {
                if (response?.data?.success) {
                    window.location = response.data.result;
                } else {
                    message.warning(response.data.message);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="login-container" style={{ position: "relative" }}>
            <div className="login-box">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Vadar
                        className="vadar"
                        style={{ width: 96, height: 96 }}
                    />
                    <Logo className="logo" />
                </div>
                <div className="login-form">
                    <Text strong style={{ fontSize: 20 }}>
                        Administration
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <Button
                            loading={loading}
                            block
                            size="large"
                            onClick={authenticationRequest}
                            style={{
                                display: "flex",
                                gap: 8,
                            }}>
                            <GoogleLogo className="google-logo" />
                            <Text style={{ fontSize: 16 }}>
                                Sign in with Google
                            </Text>
                        </Button>
                    </div>
                </div>
                <div className="tm">&#169; Roster {CURR_YEAR}</div>
            </div>
        </div>
    );
};
